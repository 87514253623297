<template>
    <div ref="el"></div>
</template>

<script>
import {onMounted, ref} from 'vue';
import {ColumnChart} from '@toast-ui/chart';
import {ymdStrToFormat} from '@/assets/js/common.utils';

export default {
    name: 'StatisticsWeeks',
    props: {
        standards: Object,
        items: Array,
    },
    setup(props) {
        const el = ref(null);

        const options = {
            chart: {width: '100%', height: 300},
            legend: {
                visible: false,
            },
            series: {
                dataLabels: {
                    visible: true,
                },
                selectable: true,
            },
            exportMenu: {
                visible: false,
            },
            tooltip: {
                template: () => {
                    return '';
                },
            },
            theme: {
                series: {
                    barWidth: 30,
                },
            },
        };

        const initChart = () => {
            const dates = (() => {
                const result = [];
                for (let i = parseInt(props.standards.start); i <= parseInt(props.standards.end); i++) {
                    result.push(i + '');
                }
                return result;
            })();

            const data = {
                categories: dates.map(x => ymdStrToFormat(x, '.')),
                series: [
                    {
                        name: 'Page View',
                        data: (() => {
                            const result = [];
                            dates.forEach(x => {
                                result.push(props.items.filter(y => y.accessYmd === x).length);
                            });
                            return result;
                        })(),
                    },
                ],
            };

            new ColumnChart({
                el: el.value,
                data,
                options,
            });
        };

        onMounted(() => {
            initChart();
        });

        return {
            el,
        };
    },
};
</script>

<style scoped></style>

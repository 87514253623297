<template>
    <div ref="el"></div>
</template>

<script>
import {onMounted, ref} from 'vue';
import {ColumnChart} from '@toast-ui/chart';

export default {
    name: 'StatisticsServices',
    props: {
        items: Array,
    },
    setup(props) {
        const el = ref(null);

        const options = {
            chart: {width: '100%', height: 300},
            legend: {
                visible: false,
            },
            xAxis: {
                label: {
                    formatter: value => {
                        // const result = value.split(' ').join('');
                        // if (result.length > 5) {
                        //     return result.substring(0, 5);
                        // }
                        return value.split(' ').join('').replace('AWS', '');
                    },
                },
            },
            series: {
                dataLabels: {
                    visible: true,
                },
                selectable: true,
            },
            exportMenu: {
                visible: false,
            },
            tooltip: {
                template: () => {
                    return '';
                },
            },
            theme: {
                series: {
                    barWidth: 30,
                },
                xAxis: {
                    label: {
                        fontSize: 8,
                        fontWeight: 'bold',
                    },
                },
            },
        };

        const initChart = () => {
            const categories = (() => {
                return Array.from(new Set(props.items.map(x => x.serviceNm)));
            })();

            const series = [
                {
                    name: 'Service',
                    data: (() => {
                        const result = [];
                        categories.forEach(x => {
                            result.push(props.items.filter(y => y.serviceNm === x).length);
                        });
                        return result;
                    })(),
                    colorByCategories: true,
                },
            ];

            const data = {
                categories,
                series,
            };

            new ColumnChart({
                el: el.value,
                data,
                options,
            });
        };

        onMounted(() => {
            initChart();
        });

        return {
            el,
        };
    },
};
</script>

<style scoped></style>

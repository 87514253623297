<template>
    <div ref="el"></div>
</template>

<script>
import {onMounted, ref} from 'vue';
import {PieChart} from '@toast-ui/chart';

export default {
    name: 'StatisticsDevices',
    props: {
        items: Array,
    },
    setup(props) {
        const el = ref(null);

        const options = {
            chart: {width: '100%', height: 300},
            legend: {
                visible: true,
            },
            series: {
                showDot: true,
                dataLabels: {
                    visible: true,
                    offsetY: -15,
                    formatter: e => {
                        return e > 0 ? e : '';
                    },
                },
                selectable: true,
            },
            exportMenu: {
                visible: false,
            },
            tooltip: {
                template: () => {
                    return '';
                },
            },
            theme: {
                series: {
                    // dataLabels: {
                    //     fontSize: 10,
                    //     fontWeight: 300,
                    //     useSeriesColor: true,
                    //     textBubble: {
                    //         visible: true,
                    //         paddingY: 3,
                    //         paddingX: 6,
                    //         arrow: {
                    //             visible: true,
                    //             width: 5,
                    //             height: 5,
                    //             direction: 'top',
                    //         },
                    //     },
                    // },
                },
            },
        };

        const initChart = () => {
            // const todayItems = props.items
            // .filter(x => x.accessYmd === props.standards.current)
            // .map(x => ({...x, accessHour: timestampToDatePart(x.createDt).hh}));

            const series = (() => {
                const result = [];
                ['PC', 'Mobile'].forEach(x => {
                    result.push({
                        name: x,
                        data: props.items.filter(y => y.accessDevice === x).length,
                    });
                });
                return result;
            })();

            const data = {
                categories: ['Device'],
                series,
            };

            new PieChart({
                el: el.value,
                data,
                options,
            });
        };

        onMounted(() => {
            initChart();
        });

        return {
            el,
        };
    },
};
</script>

<style scoped></style>

<template>
    <div ref="el"></div>
</template>

<script>
import {onMounted, ref} from 'vue';
import {LineChart} from '@toast-ui/chart';
import {timestampToDatePart} from '@/assets/js/common.utils';

export default {
    name: 'StatisticsDays',
    props: {
        standards: Object,
        items: Array,
        items2: Array,
    },
    setup(props) {
        const el = ref(null);

        const initChart = () => {
            const todayItems = props.items
                .filter(x => x.accessYmd === props.standards.current)
                .map(x => ({...x, accessHour: timestampToDatePart(x.createDt).hh}));

            const todayItems2 = props.items2.map(x => ({...x, accessHour: timestampToDatePart(x.createDt).hh}));

            const categories = (() => {
                const result = [];
                for (let i = 0; i < 23; i = i + 2) {
                    result.push(`${i}시`);
                    result.push(' ');
                }
                return result;
            })();

            const data = {
                categories,
                series: [
                    {
                        name: '페이지 뷰',
                        data: (() => {
                            const result = [];
                            for (let i = 1; i <= 23; i++) {
                                result.push(todayItems.filter(x => x.accessHour === i).length);
                            }
                            return result;
                        })(),
                    },
                    {
                        name: '순 방문자 수',
                        data: (() => {
                            const result = [];
                            for (let i = 1; i <= 23; i++) {
                                result.push(todayItems2.filter(x => x.accessHour === i).length);
                            }
                            return result;
                        })(),
                    },
                ],
            };

            const options = {
                chart: {width: '100%', height: 300},
                yAxis: {
                    scale: {
                        max: Math.max(...data.series[0].data) + 5,
                    },
                },
                legend: {
                    visible: true,
                },
                series: {
                    showDot: true,
                    dataLabels: {
                        visible: true,
                        offsetY: -15,
                        formatter: e => {
                            return e > 0 ? e : '';
                        },
                    },
                    selectable: true,
                },
                exportMenu: {
                    visible: false,
                },
                tooltip: {
                    template: () => {
                        return '';
                    },
                },
                theme: {
                    series: {
                        // dataLabels: {
                        //     fontSize: 10,
                        //     fontWeight: 300,
                        //     useSeriesColor: true,
                        //     textBubble: {
                        //         visible: true,
                        //         paddingY: 3,
                        //         paddingX: 6,
                        //         arrow: {
                        //             visible: true,
                        //             width: 5,
                        //             height: 5,
                        //             direction: 'top',
                        //         },
                        //     },
                        // },
                    },
                },
            };

            new LineChart({
                el: el.value,
                data,
                options,
            });
        };

        onMounted(() => {
            initChart();
        });

        return {
            el,
        };
    },
};
</script>

<style scoped></style>

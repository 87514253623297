<template>
    <main class="swcluster-main" id="swcluster-datahub">
        <!-- page-header -->
        <div class="page-header sw-divider">
            <div class="header-component">
                <nav class="breadcrumb">
                    <ol class="breadcrumb-wrapper">
                        <li class="breadcrumb-item"><a href="javascript:">관리자</a></li>
                        <li class="breadcrumb-item"><a href="javascript:">통계</a></li>
                    </ol>
                </nav>
                <h2 class="page-title">통계</h2>
                <div class="page-title-bottom">
                    <!--                    <a href="" class="link">-->
                    <!--                        <strong class="text-primary">미리보기</strong>-->
                    <!--                    </a>-->
                </div>
            </div>
        </div>
    </main>
    <main class="swcluster-main">
        <!-- page-body -->
        <div class="page-body">
            <!-- page-contents -->
            <div v-if="isReady" class="page-contents">
                <article class="content-section">
                    <div class="section-component" style="display: flex">
                        <!--                        <div style="border: solid gray 1px; padding: 24px">-->
                        <div style="width: 70%">
                            <header class="section-header"><h3 class="title title-sm">당일 접속 통계</h3></header>
                            <StatisticsDays :items="items" :items2="items2" :standards="standards" />
                        </div>

                        <div style="width: 30%">
                            <header class="section-header"><h3 class="title title-sm">시스템 환경</h3></header>
                            <StatisticsDevices :items="items" />
                        </div>
                    </div>

                    <div class="section-component" style="display: flex">
                        <div style="width: 100%">
                            <header class="section-header"><h3 class="title title-sm">주간 접속 통계</h3></header>
                            <StatisticsWeeks :items="items" :standards="standards" />
                        </div>
                    </div>

                    <div class="section-component" style="display: flex">
                        <div style="width: 100%">
                            <header class="section-header"><h3 class="title title-sm">주간 서비스별 접속 통계</h3></header>
                            <StatisticsServices :items="items" />
                        </div>
                    </div>
                </article>
            </div>
        </div>
        <!-- //page-body -->
    </main>
</template>

<script>
import {reactive, ref} from 'vue';
import {dateToDatePart, getItems, lengthCheck} from '@/assets/js/common.utils';
import ApiService from '@/assets/js/api.service';
import StatisticsWeeks from '@/components/statistics/StatisticsWeeks';
import StatisticsDays from '@/components/statistics/StatisticsDays';
import {hideLoading, showLoading} from '@/assets/js/common.alert';
import StatisticsDevices from '@/components/statistics/StatisticsDevices';
import StatisticsServices from '@/components/statistics/StatisticsServices';

export default {
    name: 'Statistics',
    components: {StatisticsServices, StatisticsDevices, StatisticsDays, StatisticsWeeks},
    setup() {
        showLoading();

        const isReady = ref(false);
        const items = ref([]);
        const items2 = ref([]);
        const standards = reactive({
            current: '',
            start: '',
            end: '',
        });

        const initStandards = () => {
            const currentDate = new Date();
            const day = currentDate.getDay();
            const startDiff = currentDate.getDate() - day;
            const endDiff = currentDate.getDate() + (6 - day);

            standards.current = dateToDatePart(currentDate).ymd;
            standards.start = dateToDatePart(new Date(currentDate.setDate(startDiff))).ymd;
            standards.end = dateToDatePart(new Date(currentDate.setDate(endDiff))).ymd;
        };

        const getAccessLogList = () => {
            Promise.all([
                ApiService.query('/v1/accesses/logs', {startAccessYmd: standards.start, endAccessYmd: standards.end}),
                ApiService.query('/v1/accesses/logs/uvs', {currentAccessYmd: standards.current}),
            ]).then(([res1, res2]) => {
                if (lengthCheck(res1)) {
                    items.value = getItems(res1);
                } else {
                    alert('권한이 없거나 잘못 인도되었습니다.');
                    window.history.back();
                }

                if (lengthCheck(res2)) {
                    items2.value = getItems(res2);
                } else {
                    window.history.back();
                }

                isReady.value = true;
                hideLoading();
            });
        };

        initStandards();
        getAccessLogList();

        return {
            isReady,
            items,
            items2,
            standards,
        };
    },
};
</script>

<style scoped></style>
